import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import routes from "../../navigation/routes";
import {withTranslation} from "react-i18next";
import React from 'react';
import I18n from "../i18n/i18n";
import LogoImage from "../../assets/images/common/logo.svg";
import Translator from "../i18n/Translator";

class Navigation extends React.Component {
    render() {

        return <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href={routes.ROOT}><img className="logo-horizontal" src={LogoImage}
                                                      alt={"logo"}/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="navbar-items">
                        <Nav.Link href={routes.ROOT}><Translator path={'navbar.home'}/></Nav.Link>
                        <Nav.Link href={routes.PRODUCT}><Translator path={'navbar.product'}/></Nav.Link>
                        <Nav.Link href={routes.ABOUT_US}><Translator path={'navbar.about_us'}/></Nav.Link>
                        <Nav.Link href={routes.DOCS}><Translator path={'navbar.docs'}/></Nav.Link>
                        <I18n/>
                        <Nav.Link href={routes.CONTACT_US}>
                            <button style={{color: '#FFF'}} className="short-button"><Translator
                                path={'navbar.contact'}/></button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    }


}

const NavigationTranslated = withTranslation()(Navigation)

export default NavigationTranslated
