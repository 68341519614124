import React from 'react';

const BrazilianFlagIcon = () => {
    const flag = {
        width: '80px',
        height: '50px',
        backgroundColor: '#cccccc',
        position: 'relative',
        borderRadius: '5px'
    };

    const diamond = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '30px',
        height: '30px',
        backgroundColor: '#fff',
        transform: 'translate(-50%, -50%) rotate(145deg) skew(20deg)',
    };

    const circle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '15px',
        height: '15px',
        backgroundColor: '#cccccc',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
    };

    return (
        <div className="holographic-effect" style={flag}>
            <div style={diamond}>
            </div>
            <div className="holographic-effect" style={circle}></div>
        </div>
    );
};

export default BrazilianFlagIcon;