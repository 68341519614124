const auth = {
    columns: [
        {
            label: 'Name',
            field: 'name',
            sort: 'asc'
        },
        {
            label: 'Type',
            field: 'type',
            sort: 'asc'
        },
        {
            label: 'Description',
            field: 'description',
            sort: 'asc'
        },
        {
            label: 'Example',
            field: 'example',
            sort: 'asc'
        }
    ],
    rows: [
        {
            'name': 'Client Id',
            'kind': 'String',
            'description': 'Provided by admin',
            'example': '"clientId": "clientId_xxx"',
        },
        {
            'name': 'Client Secret',
            'kind': 'String',
            'description': 'Provided by admin',
            'example': ' "clientSecret": "clientSecret_xxx"',
        }
    ]
}

export default auth;