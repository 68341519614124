import {Switch} from "@mui/material";
import Navigation from "../components/navigation/Navigation";
import AD from "../assets/images/product/ad_display.svg"
import FULL from "../assets/images/product/full_lock.svg"
import PARTIAL from "../assets/images/product/partial_lock.svg"
import React, {useEffect, useRef, useState} from 'react';
import {Image} from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Translator from "../components/i18n/Translator";
import {motion} from "framer-motion";
import 'bootstrap/dist/css/bootstrap.min.css';
import CheckLi from "../components/ui/CheckLi";
import IconPresent from "../assets/images/icon/present.svg";
import IconBag from "../assets/images/icon/bag.svg";
import IconSearch from "../assets/images/icon/search.svg";
import CommercialCard from "../components/ui/CommercialCard";
import {useTranslation} from "react-i18next";
import Footer from "../components/common/Footer";
import product from "../helpers/constants/product";
import routes from "../navigation/routes";
import {Link as ScrollLink} from "react-scroll";
import {NavItem} from "@trendmicro/react-sidenav";
import PrimaryButton from "../components/common/PrimaryButton";

function Product() {
    const {t} = useTranslation()
    const [activeOption, setActiveOption] = useState(AD);
    const [activeRoute, setActiveRoute] = useState(routes.AD);
    const [txtOption, setTxtOption] = useState(false);
    const [txtTitle, setTxtTitle] = useState(product.AD_DISPLAY);
    const [txtContent, setTxtContent] = useState("");
    const [iconRotated, setIconRotated] = useState(false);

    const [width, setWidth] = useState(0)
    const carousel = useRef()

    useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth)
    }, []);

    const handleSwitchChange = (option) => {
        setActiveOption(option);
        setTxtOption(false);

        switch (option) {
            case AD:
                setTxtTitle('product.ad_display');
                setActiveRoute(routes.AD);
                break;
            case FULL:
                setTxtTitle('product.full_lock');
                setActiveRoute(routes.FULL);
                break;
            case PARTIAL:
                setTxtTitle('product.partial_lock');
                setActiveRoute(routes.PARTIAL);
                break;
            default:
                setTxtTitle('');
        }
    };

    const handleTextChange = (option) => {
        if (txtOption === false) {
            setTxtOption(true);
            setIconRotated(true);

            switch (option) {
                case AD:
                    setTxtContent('product.ad_display_preview');
                    break;
                case FULL:
                    setTxtContent('product.full_lock_preview');
                    break;
                case PARTIAL:
                    setTxtContent('product.partial_lock_preview');
                    break;
                default:
                    setTxtContent('');
            }
        } else {
            setTxtOption(false);
            setIconRotated(false);
        }
    };
    return (
        <>
            <Navigation/>
            <section>
                {/* change menu */}
                <div className="product-showcase">
                    <div className="glass">
                        <section className="product-showcase-options">
                            <Fade clear>
                                <h3 className="showcase-label">
                                    <div>1</div>
                                    <Translator path={'product.step_one'}/>
                                </h3>
                            </Fade>

                            <Fade left big cascade>
                                <section className="product-showcase-accordion">
                                    <div className="switch-card">
                                        AD Display
                                        <Switch
                                            checked={activeOption === AD}
                                            onChange={() => handleSwitchChange(AD)}
                                        />
                                    </div>
                                    <div className="switch-card">
                                        Full Lock
                                        <Switch
                                            checked={activeOption === FULL}
                                            onChange={() => handleSwitchChange(FULL)}
                                        />
                                    </div>
                                    <div className="switch-card">
                                        Partial Lock
                                        <Switch
                                            checked={activeOption === PARTIAL}
                                            onChange={() => handleSwitchChange(PARTIAL)}
                                        />
                                    </div>
                                </section>
                            </Fade>

                        </section>
                        <section>
                            <Fade clear>
                                <h3 className="showcase-label">
                                    <div>2</div>
                                    <Translator path={'product.step_two'}/>
                                </h3>
                            </Fade>
                            <div className="minimalist-card">
                                <div className="minimalist-card-content">
                                    {txtOption ? (

                                        <p><Translator path={txtContent}/><br/><br/>

                                            <ScrollLink to={activeRoute} smooth={true} duration={200}
                                                        className="remove-link-style">
                                                <NavItem eventKey={activeRoute}>
                                                    <PrimaryButton text={<Translator path={'slide.learn_more'}/>}/>
                                                </NavItem>
                                            </ScrollLink>
                                        </p>


                                    ) : (
                                        <Image className="responsive-image" src={activeOption} alt="Product Image"/>
                                    )}
                                </div>
                                <div>
                                    <h2><Translator path={txtTitle}/></h2>
                                    <i onClick={() => handleTextChange(activeOption)}
                                       className={`bi bi-plus-circle-fill ${iconRotated ? 'rotated' : ''}`}></i>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <section className="min-vh-100 benefits-section">
                    <h2><Translator path={'product.benefits_title'}/></h2>
                    <section className="product-list">
                        <ul>
                            <CheckLi content={<Translator path={'product.benefit_customize'}/>}/>
                            <CheckLi content={<Translator path={'product.benefit_adm_panel'}/>}/>
                        </ul>
                        <ul>
                            <CheckLi content={<Translator path={'product.benefit_installation'}/>}/>
                            <CheckLi content={<Translator path={'product.benefit_no_mei_required'}/>}/>
                            <CheckLi content={<Translator path={'product.benefit_switch_easily'}/>}/>
                        </ul>
                    </section>
                    <section className="table-wrapper">
                        <table className="striped-table">
                            <thead>
                            <tr>
                                <td><Translator path={'product.header_feature'}/></td>
                                <td><Translator path={'product.ad_display'}/></td>
                                <td><Translator path={'product.full_lock'}/></td>
                                <td><Translator path={'product.partial_lock'}/></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><Translator path={'product.ad_frequency'}/></td>
                                <td><i className="bi bi-check"></i></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><Translator path={'product.list_of_apps'}/></td>
                                <td></td>
                                <td></td>
                                <td><i className="bi bi-check"></i></td>
                            </tr>
                            <tr>
                                <td><Translator path={'product.hard_lock'}/></td>
                                <td></td>
                                <td><i className="bi bi-check"></i></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><Translator path={'product.ads_money'}/></td>
                                <td><i className="bi bi-check"></i></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><Translator path={'product.custom_message'}/></td>
                                <td><i className="bi bi-check"></i></td>
                                <td><i className="bi bi-check"></i></td>
                                <td><i className="bi bi-check"></i></td>
                            </tr>

                            </tbody>
                        </table>
                    </section>

                </section>

                {/* AD DISPLAY */}
                <section id={routes.AD} className="min-vh-100 product-details-container">
                    <h2><Translator path={'product.details_title'}/></h2>
                    <div className="product-details">
                        <div className="product-image">
                            <Image className="responsive-image" src={AD} alt="Product Image"/>
                        </div>
                        <div className="product-description">
                            <h2><Translator path={'product.ad_display'}/></h2>
                            <br/><br/>
                            <p><Translator path={'product.ad_display_detailed'}/></p>
                            <br/><br/>
                        </div>
                    </div>
                </section>

                {/* PARTIAL LOCK */}
                <section id={routes.PARTIAL} className="min-vh-100 product-details-container">
                    <div className="product-details reverse">
                        <div className="product-image">
                            <Image className="responsive-image" src={PARTIAL} alt="Product Image"/>
                        </div>
                        <div className="product-description">
                            <h2><Translator path={'product.partial_lock'}/></h2>
                            <br/><br/>
                            <p><Translator path={'product.partial_lock_detailed'}/></p>
                            <br/><br/>
                        </div>
                    </div>
                </section>

                {/* FULL LOCK */}
                <section id={routes.FULL} className="min-vh-100 product-details-container">
                    <div className="product-details">
                        <div className="product-image">
                            <Image className="responsive-image" src={FULL} alt="Product Image"/>
                        </div>
                        <div className="product-description">
                            <h2><Translator path={'product.full_lock'}/></h2>
                            <br/><br/>
                            <p><Translator path={'product.full_lock_detailed'}/></p>
                            <br/><br/>
                        </div>
                    </div>
                </section>


                <section>
                    <div className="commercial">
                        <header className="commercial-header">
                            <h4><Translator path={'commercial.try'}/></h4>
                            <p><Translator path={'commercial.commercial_proposal'}/></p>
                        </header>
                        <motion.div ref={carousel} className="carrousel" whileTap={{cursor: "grabbing"}}>
                            <motion.div drag="x" dragConstraints={{right: 0, left: -width}} className="inner-carrousel">
                                <motion.div className="item">
                                    <CommercialCard
                                        height={480}
                                        icon={IconPresent}
                                        title={<Translator path={'commercial.free_trial'}/>}
                                        body={
                                            <p className="format-text">
                                                <Translator path={'commercial.free_trial_txt'}/> <b
                                                className="yellow-text"><Translator
                                                path={'commercial.free_trial_bolder'}/></b>
                                            </p>
                                        }
                                    />
                                </motion.div>
                                <motion.div className="item">
                                    <CommercialCard
                                        height={480}
                                        className="custom-card-body"
                                        icon={IconBag}
                                        title={<Translator path={'commercial.indicative'}/>}
                                        body={
                                            <div>
                                                <CheckLi content={t('commercial.indicative_txt_1')}/>
                                                <br/>
                                                <CheckLi content={t('commercial.indicative_txt_2')}/>
                                            </div>
                                        }
                                    />
                                </motion.div>
                                <motion.div className="item">
                                    <CommercialCard
                                        height={480}
                                        className="custom-card-body"
                                        icon={IconSearch}
                                        title={<Translator path={'commercial.get_a_demo'}/>}
                                        body={
                                            <p className="format-text">
                                                <Translator path={'commercial.get_a_demo_txt'}/> <b
                                                className="yellow-text"><Translator
                                                path={'commercial.get_a_demo_bolder'}/></b>
                                            </p>
                                        }
                                    />
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </div>
                </section>
                <Footer/>
            </section>
        </>
    );
}

export default Product;

