import Card from 'react-bootstrap/Card';
import {Alert} from "react-bootstrap";
import {Element} from 'react-scroll';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import toastHelper from "../../helpers/toast-helper";
import HttpBadge from "./HttpBage";
import React from "react";
import Translator from "../i18n/Translator";


function ApiCard(props) {

    const data = {
        columns: [
            {
                label: 'Name',
                field: 'name',
                sort: 'asc'
            },
            {
                label: 'Example',
                field: 'example',
                sort: 'asc'
            }
        ],
        rows: [
            {
                'name': 'TAG',
                'description': 'TAG from device',
                'example': '3B2DD0F',
            }
        ]
    }

    const urlBase = "https://partner.collateralasaservice.api.dev.globetek.com/partner/collateral/"
    const endpoint = urlBase + props.endpoint

    return (
        <Element name={props.id}>
            <Card className="custom-card">
                <Card.Body>
                    <Card.Title>{props.title}
                        <HttpBadge method={props.method}/>
                    </Card.Title>
                    <Card.Text>{props.text}</Card.Text>
                    <br/>

                    <label className="card-label"><i className="bi bi-hash"></i><Translator path={'endpoint.params'}/></label>
                    <Alert className="alerta response" responsive>
                        {props.params}
                    </Alert>
                    <br/><br/>

                    <label className="card-label"><i className="bi bi-hash"></i><Translator path={'endpoint.endpoint'}/></label>

                    <section className="copy-input" onClick={() => {
                        navigator.clipboard.writeText(endpoint).then(r => toastHelper.showInfo("text on clipboard!"))
                    }}>
                        <div className="invisible-input">
                            {urlBase}
                            {props.path}
                        </div>
                        <i className="bi bi-clipboard"></i>
                    </section>
                    <br/><br/>

                    <label className="card-label"><i className="bi bi-hash"></i><Translator path={'endpoint.expected_response'}/></label>
                    <Alert className="alert response">
                        {props.response}
                    </Alert>
                </Card.Body>
            </Card>
        </Element>
    );
}

export default ApiCard;