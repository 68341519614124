import React from "react";
import Slide from "react-reveal/Reveal";
import Translator from "../i18n/Translator";

function IllustrativeGraphic() {
    return (
        <div className="graphic">
            <p><Translator path={'about_us.grow'}/></p>
            <Slide top cascade>
                <div className="bar-container">
                    <div className="bar bar-1">+30</div>
                    <div className="bar bar-2">+40</div>
                    <div className="bar bar-3">+60</div>
                    <div className="bar bar-4">+90</div>
                </div>
            </Slide>
        </div>
    );
}

export default IllustrativeGraphic;