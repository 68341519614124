import React from "react";
import Translator from "../i18n/Translator";

function BadgesContainer() {
    const badge = {
        display: 'inline-block',
        padding: '10px 15px',
        minWidth: '100px',
        backgroundColor: '#dbebff',
        color: '#0055AA',
        borderRadius: '60px',
        textAlign: 'center',
        border: 'solid 1px #0055AA'
    }

    const container = {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: '30px'
    }

    return (
        <div style={container}>
            <span style={badge}><Translator path={'about_us.tech'}/></span>
            <span style={badge}><Translator path={'about_us.innovation'}/></span>
            <span style={badge}><Translator path={'about_us.security'}/></span>
        </div>
    );
}

export default BadgesContainer;