import React from "react";

function CheckLi(props) {
    const style = {
        display: 'flex',
        gap: '20px',
        alignItems: 'center'
    }
    return (
        <li style={style}><i style={{color: '#0055AA'}} className="bi bi-check-circle-fill"></i> {props.content}</li>
    );
}

export default CheckLi;