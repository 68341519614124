import {Route, Routes} from "react-router-dom";
import Docs from "./pages/Docs";
import Home from "./pages/Home";
import routes from "./navigation/routes";
import "./assets/styles/style.css";
import "./components/i18n/i18n";
import ContactUs from "./pages/ContactUs";
import Product from "./pages/Product";
import AboutUs from "./pages/AboutUs";

function App() {
  return (
      <>
          <Routes>
              <Route path={routes.ROOT} element={<Home/>}/>
              <Route path={routes.DOCS} element={<Docs/>}/>
              <Route path={routes.CONTACT_US} element={<ContactUs/>}/>
              <Route path={routes.PRODUCT} element={<Product/>}/>
              <Route path={routes.ABOUT_US} element={<AboutUs/>}/>
          </Routes>
      </>

  );
}

export default App;
