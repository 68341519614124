import React from "react";

function TextWithQuotes(props) {
    const title = {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '2em'
    }

    const content = {
        maxWidth: '600px',
        fontSize: '1.3em',
        color: 'var(--font-secondary-color)',
        display: 'grid',
        gap: '10px',
    }

    const rotate = {
        display: 'inline-block',
        transform: 'rotate(180deg)'
    }

    return (
        <div>
            <h2 style={title}>{props.title}</h2>
            <br/><br/>
            <p style={content}><i className="bi bi-quote"></i>
                {props.content} <i className="bi bi-quote" style={rotate}></i>
            </p>
            <br/><br/>
        </div>
    );
}

export default TextWithQuotes;