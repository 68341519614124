import React from "react";
import {Parallax} from "react-parallax";
import Image from "../../assets/images/about_us/partners.png"
import Fade from "react-reveal/Fade";
import TextWithQuotes from "./TextWithQuotes";
import Translator from "../i18n/Translator";
import { useTranslation } from 'react-i18next'

function MediumScrollEffect() {
    // Replace this with your translation logic to get the text
    const { t } = useTranslation()
    const brazilianCaseText = t('about_us.brazilian_case');
    const paragraphs = brazilianCaseText.split('\n');

    return (
        <div className="medium-container">
            <Parallax style={{height: '400px'}} strength={-600} bgImage={Image}></Parallax>
            <div className="medium-content">
                <br/>
                <Fade clear>
                    <h2><Translator path={'about_us.lander_on_brazil'}/></h2>
                </Fade>
                <br/>
                <TextWithQuotes

                    content={
                    <div>
                        {paragraphs.map((paragraph, index) => (
                            <>
                                <p key={index}>{paragraph}</p>
                                <br/>
                            </>
                        ))}
                    </div>
                    }
                />

            </div>
        </div>
    );
}

export default MediumScrollEffect;
