export default {
    translations: {
        toast_helper: {
            send_email: {
                error: 'El mensaje no pudo ser enviado, por favor inténtelo nuevamente',
                success: 'El mensaje se envió exitosamente',
            }
        },
        navbar: {
            home: 'Inicio',
            docs: 'Documentos',
            contact: 'Contáctenos',
            language: 'Idioma',
            about_us: 'Acerca de nosotros',
            product: 'Producto'
        },
        sidebar: {
            home: 'Inicio',
            how_it_works: 'Cómo funciona',
            download: 'Descargar',
            getting_started: 'Auth'
        },
        endpoint: {
            get_collateral: 'Upload IMEI',
            change_due_date: 'Get IMEI status',
            release_collateral: 'Get Collateral',
            get_all_collaterals: 'Lock Collateral',
            get_all_by_tag: 'Unlock Collateral',
            update_product_item_id: 'Update User ID',
            params: 'Parámetros',
            endpoint: 'Punto de Acceso',
            expected_response: 'Respuesta Esperada',
            release:'Release Collateral',
            send_message:'Send Message',
            offline_unlock: 'Offline Unlock',
            STOP_BLINK_REMINDER: 'Stop Blink Reminder',
            START_BLINK_REMINDER: 'Start Blink Reminder'
        },
        docs: {
            docs: 'Documentos',
            how_it_works: 'Cómo funciona',
            intro: 'La finalidad de este documento es explicar con más detalles la solución Globetek para dispositivos móviles. Encontrarás una descripción general del ciclo de vida de la aplicación, cómo utilizar la API, obtener tus credenciales, activar y liberar la garantía.',
            purpose: 'El propósito de este documento es explicar con más detalle la solución Globetek para dispositivos móviles. Encontrarás una descripción general del ciclo de vida de la aplicación, cómo utilizar la API, obtener tus credenciales, activar y liberar la garantía.',
            lifecycle: 'Ciclo de Vida de la Aplicación de Colateral',
            lifecycle_details: 'Básicamente, nuestra aplicación sigue una lógica de funcionamiento; tienen un "ciclo de vida", lo que significa que esta aplicación se configurará, luego se utilizará para ese propósito y, al final, la aplicación se "liberará", se "desactivará". Porque el propósito ya se ha cumplido.',
            get_collateral: 'Obtenga garantía por IMEI o ID de garantía, devolverá el objeto colateral.',
            get_collateral_params: 'Parâmetros em consulta:',
            IMEI: 'IMEI - El IMEI del dispositivo | opcional',
            MESSAGE: 'Mensaje - El mensaje a enviar | requerido',
            ID: 'ID - La identificación de la garantía creada para el dispositivo | opcional',
            change_due_date: 'Punto de acceso que cambia la fecha de vencimiento del colateral.',
            release_collateral: 'Punto de acceso utilizado para liberar el colateral.',
            get_all_collaterals: 'Bloquee una garantía por IMEI o ID de garantía.',
            get_all_by_tag: 'Desbloquee una garantía por IMEI o ID de garantía.',
            update_product_item_id:'Liberar una garantía por IMEI o ID de garantía.',
            collection: 'Descarga nuestra colección de Postman',
            download: 'Descargar',
            generate_key: 'Generar Clave API',
            api_key: 'Punto de acceso utilizado para obtener un token de acceso. Es necesario proporcionar tus credenciales (token) para usar cada punto de acceso.',
            provided_by: 'Proporcionado por el administrador',
            imei_return: 'Carga un nuevo IMEI del dispositivo y devuelve el objeto IMEI del dispositivo creado.',
            get_imei: 'Obtenga un objeto IMEI del dispositivo. Devuelve el objeto IMEI del dispositivo encontrado.',
            bear_token: 'Importante: después de generar el token de autenticación, debe pasarlo a las otras API como un token de portador para evitar la respuesta 401.',
            mandatory_parameter: 'Parámetros en la consulta: imei - El IMEI del dispositivo | obligatorio',
            update_user_id: 'Actualiza la ID de usuario de la garantía y el IMEI del dispositivo.',
            send_message: 'Envía un mensaje a un dispositivo. Máximo 500 caracteres.',
            params_unlock_IMEI: 'imeis - El IMEI del dispositivo',
            params_unlock_PASSKEY: 'PASSKEY - La clave de acceso del dispositivo',
            message_blink_reminder: 'message: String - texto del mensaje: límite de 200 caracteres',
            imei_blink_reminder: 'imeis: Array(String) -> lista de imeis',
            tel: 'tel: String -> telefono de contacto',
            interval: 'interval: Integer -> con qué frecuencia aparece el mensaje en segundos [3..86400',
            timeLimitEnable: 'timeLimitEnable: Boolean -> limitar el tiempo durante el cual el mensaje no aparecerá - Ejemplo: desactivar el recordatorio intermitente de 23 a 7 horas; timeLimitEnable: verdadero',
            daysLimitEnable: 'daysLimitEnable: Boolean -> límite de días en los que el mensaje no aparecerá - Ejemplo: desactivar el recordatorio intermitente desde las 6 a. m. del domingo hasta las 7 a. m. del lunes; timeLimitEnable:false',
            daysLimit: 'daysLimit: Array(Integer) - días de la semana en los que no aparecerá el mensaje - El tamaño del array debe ser 2, los valores de daysLimit deben estar entre 0 y 6, - Ejemplo (0: domingo, 1: lunes... y 6: sábado)',
            timeLimit: 'timeLimit: Array(Integer) - hora de inicio y finalización el mensaje no aparecerá - El tamaño de la matriz debe ser 2, los valores de timeLimit deben estar entre 0 y 23. - Ejemplo: [23, 7] cuando desea desactivar el recordatorio intermitente de 11 p. m. a 7 a. m.'
        },
        home: {
            digital_solution: 'Solución digital multifuncional',
            unique_needs: 'La solución que se adapta a las necesidades únicas de tu negocio.',
            reduce_credit_risk: 'Reducir el riesgo crediticio',
            approval_rates: 'Aumentar las tasas de aprobación',
            reduce_fraud: 'Reducir el fraude',
            earn_money: 'Ganar dinero mientras tus clientes ven anuncios',
            payment: 'Lograr el pago de tus préstamos utilizando nuestras soluciones digitales de bloqueo',
            how_it_works: 'Cómo funciona',
            complete_solution: '¡La solución digital más completa! Fácil de instalar y flexible de implementar',
            set_up: 'Configuración',
            rules: 'El prestamista especifica las reglas de bloqueo',
            installation: 'Instalación',
            apk_installation: 'El cliente instala la APK en el dispositivo y permite los permisos',
            locking: 'Bloqueo / Desbloqueo',
            unlock_rules: 'El dispositivo se desbloquea después del pago',
            book_a_demo: '¡Reserva una demostración!',
            our_competitive: 'Nuestras ventajas competitivas',
            advantages: 'Ventajas',
            device_coverage: 'Cobertura de dispositivos',
            brands: 'Más marcas compatibles: Samsung, LG, Motorola y Xiaomi. Otras pendientes...',
            customizable: 'Personalizable',
            not_required: 'Proceso de instalación más flexible, que no requiere reiniciar o resetear el dispositivo. No se necesita el número IMEI.',
            flexible: 'Bloqueo Flexible',
            more_flexible: 'Más Flexible',
            lock_implementation: 'Implementación de Bloqueo',
            meet: 'Conoce',
            our_solutions: 'Nuestras soluciones',
            learn_more: 'Obtén más información sobre nuestra solución de bloqueo y logra mayores rendimientos con la mejor combinación para tu modelo de negocio.',
            new: 'Nuevo',
            ad_lock: 'Genera ingresos mientras el teléfono de tus clientes está bloqueado. Se reproducirán anuncios cada vez que el usuario final acceda a cualquier aplicación, lo que les permitirá monetizar su bloqueo.',
            full_lock: 'Logra el pago de tus cuotas de préstamo. Hoy en día, nadie permitiría que su teléfono esté bloqueado. Tus clientes pagarán su tarifa sin dudarlo.',
            partial_lock: 'Crea una lista negra de las aplicaciones que deseas bloquear. De esta manera, tus clientes no podrán acceder a ellas mientras el bloqueo parcial esté activo.',
            combined_lock: 'Logra el pago de tus cuotas identificando la mejor combinación para tus clientes. Un bloqueo adaptado a tu modelo de negocio.',
            credit_score: 'Recopila datos de tus clientes potenciales y reduce el riesgo al otorgar préstamos. Crea tu propia puntuación a partir de la información recibida.',
            try_for_free: 'Pruébalo gratis',
            plans: 'Planes',
            take_control: 'Toma el control del recorrido del cliente. ¡Activa la tecnología que mejor se adapte a tu negocio!',
            privacy_policy: 'Política de privacidad',
            terms_of_service: 'Términos de servicio',
            our_partners: 'Nuestros socios',
            solution_for_partners: 'Ofrecemos una amplia gama de soluciones de bloqueo, con un enfoque en las necesidades únicas de cada país y socio.'
        },
        slide: {
            digital_solution: 'SOLUCIÓN DIGITAL',
            efficiency: 'Eficiencia en la Originación de Créditos y Reducción de Impagos',
            learn_more: 'Obtener más información',
            fraud: 'Reducción del Fraude y Mayor Seguridad',
            flexibility: 'Flexibilidad y Control a Tu Alcance',
        },
        commercial: {
            try: '¡Pruébalo gratis!',
            commercial_proposal: 'Propuesta Comercial',
            free_trial: 'Prueba gratuita',
            free_trial_txt: 'Obtén un período de prueba gratuito para ver los beneficios de nuestra solución',
            free_trial_bolder: 'en la práctica.',
            indicative: 'Indicativo',
            indicative_txt_1: 'Pago único - por dispositivo activo. 6.00 USD.',
            indicative_txt_2: 'Pago mensual - valores reales por determinar, dependiendo del volumen. 0.60 USD.',
            get_a_demo: 'Obtén una demostración',
            get_a_demo_txt: 'Contáctanos y obtén una demostración.',
            get_a_demo_bolder: 'Observa cómo funciona.'
        },
        product: {
            step_one: '¡Prueba uno! Cambia en cualquier momento',
            step_two: 'Toca para ver más de cerca',
            ad_display: 'Visualización de Anuncios',
            full_lock: 'Bloqueo Completo',
            partial_lock: 'Bloqueo Parcial',
            ad_display_preview: 'Nuestra opción de visualización de anuncios es perfecta para monetizar con nuestra tecnología de vanguardia. Puedes elegir entre un anuncio tradicional o una imagen propia que se puede cerrar después de unos segundos.',
            full_lock_preview: 'La función de Bloqueo Completo te permite restringir globalmente las funcionalidades de tu teléfono móvil, permitiendo solo llamadas telefónicas y acceso a tu sitio web (opcional).',
            partial_lock_preview: 'El Bloqueo Parcial te permite restringir funciones específicas de tu teléfono móvil en función de una lista personalizada.',
            learn_more: 'Obtener más información',
            benefits_title: 'Gestiona tu solución digital',
            benefit_customize: 'Personaliza la aplicación con los colores de tu marca y textos que tengan sentido para ti',
            benefit_adm_panel: 'Controla todo a través del panel administrativo o la API',
            benefit_installation: 'Instala mediante Código QR (usando el Sistema de Gestión Android) o instala nuestra aplicación.',
            benefit_no_mei_required: 'No se requiere IMEI ni reinicio de fábrica',
            benefit_switch_easily: 'Cambia fácilmente las funciones',
            header_feature: 'Funciones',
            ad_frequency: 'Controla la frecuencia de los anuncios',
            list_of_apps: 'Utiliza una lista de aplicaciones permitidas',
            hard_lock: 'Bloqueo completo',
            ads_money: 'Gana dinero con anuncios',
            custom_message: 'Personaliza tu mensaje',
            details_title: 'Conoce los detalles',
            ad_display_detailed: 'Nuestra opción de visualización de anuncios es perfecta para monetizar con nuestra tecnología de vanguardia. Puedes elegir entre un anuncio tradicional o una imagen propia que se puede cerrar después de unos segundos. La pantalla se muestra periódicamente al cliente según su configuración. Además, nuestro producto no interfiere en situaciones de emergencia.',
            full_lock_detailed: 'El Bloqueo Completo te permite restringir funciones específicas de tu teléfono móvil en función de una lista personalizada. Cuando el cliente selecciona una aplicación no permitida, aparece un mensaje emergente en su pantalla informándole que la función no está disponible.',
            partial_lock_detailed: 'La función de Bloqueo Completo te permite restringir globalmente las funcionalidades de tu teléfono móvil, permitiendo solo llamadas telefónicas y acceso a tu sitio web (opcional). El bloqueo, al igual que otras opciones, se puede programar, lo que te permite bloquear y desbloquear de forma remota cuando lo desees.',
        },
        about_us: {
            who_are_we: '¿Quiénes somos?',
            introduction: 'Somos un equipo tecnológico motivado enfocado en innovar soluciones digitales. Nuestro viaje comenzó en Brasil con un avance financiero que tiene el potencial de generar resultados impresionantes.',
            grow: '¡Estamos aquí para hacer crecer tus números!',
            tech: '#tecnología',
            innovation: '#innovación',
            security: '#seguridad',
            results: 'Nuestros resultados reales en Prestamista Subprime Brasileño',
            risk_reduction: 'Reducción significativa del riesgo para diferentes perfiles de crédito, ¡consulta las cifras!',
            loans: 'Préstamos financiados utilizando tecnología de bloqueo de teléfonos',
            defaults: 'Reducción de incumplimientos',
            approvals: 'Aumento en aprobaciones',
            lander_on_brazil: 'El Prestamista Subprime más Grande de Brasil',
            brazilian_case: 'Nacimos para democratizar el acceso al crédito en Brasil, y para este propósito, ofrecemos las mejores condiciones para todos los perfiles. Y podemos hacerlo gracias a la tecnología de Globetek. Con la tecnología, podemos controlar nuestro riesgo, ampliar el número de clientes y retornos. La tecnología ha sido esencial para nuestro crecimiento. \n Para que te hagas una idea de lo impactante que fue la tecnología, nuestras ventas aumentaron 3 veces y hubo un crecimiento exponencial. La tecnología abordó nuestro mayor desafío de gestionar la morosidad, y nuestra empresa en particular se ocupó de un grupo de alto riesgo.'
        },
        faq: {
            faq: 'Preguntas frecuentes',
            installation: 'Cómo instalar la aplicación',
            installation_answer: 'Instalarás un APK, también proporcionamos una guía paso a paso para ayudarte en este proceso.',
            how_to_lock: 'Cómo bloquear un dispositivo',
            how_to_lock_answer: 'Puedes bloquear y desbloquear fácilmente usando nuestro panel administrativo',
            billing: 'Cómo funciona el proceso de facturación',
            billing_answer: 'Al principio, ofrecemos una prueba gratuita para nuestros socios; cuando el socio decide trabajar con nosotros, solo requerimos una tarifa de activación por dispositivo y una tarifa de mantenimiento más económica por cada dispositivo.',
            factory_reset: '¿Necesita restablecimiento de fábrica?',
            factory_reset_answer: '¿Necesita restablecimiento de fábrica?',
            compatible_devices: 'Dispositivos compatibles',
            compatible_devices_answer: 'Trabajamos con Samsung, Motorola, Xiaomi y LG',
            pii: '¿La aplicación Globetek recopila información de identificación personal (PII)?',
            pii_answer: 'La aplicación Globetek solo recopila información específica del dispositivo que no se puede vincular a una persona. Por lo tanto, no es posible identificar a una persona en función de la información recopilada.',
        },
        contact: {
            title: 'Formulario de contacto',
            name: 'Nombre',
            phone: 'Teléfono',
            message: 'Mensaje',
            submit: 'Enviar',
        },
        footer: {
            waiting: '¿Qué estás esperando?',
            location: 'Ubicación',
            peru: 'Perú',
            colombia: 'Colombia',
            guatemala: 'Guatemala',
            uzbekistan: 'Uzbekistán',
            bolivia: 'Bolivia',
            latvia: 'Letonia',
            pages: 'Páginas',
            solution: 'Solución',
            product: 'Producto',
            about_us: 'Sobre nosotros',
            documentation: 'Documentación',
            contact_us: 'Contáctenos',
            privacy_policy: 'Política de privacidad',
            terms_of_service: 'Términos de servicio',
            globetek: '© Globetek Holdings',
        }
    }
}
