import React from 'react';
import {Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ImageSecured from "../../assets/images/slideshow/d3bue29.png";
import ImagePortal from "../../assets/images/slideshow/ab2ef3n.png";
import Translator from "../i18n/Translator";
import routes from "../../navigation/routes";
import PrimaryButton from "../common/PrimaryButton";

const SlideShow = () => {
    const buttonStyle = {
        width: "50px",
        height: "50px", // Add height to make the buttons visible
        background: '#0055AA', // Add a background color
        border: 'none', // Add a border for better visibility
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '30px',
        margin: '5px',
    };

    const properties = {
        prevArrow: <button style={buttonStyle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" className="bi bi-chevron-left"
                 viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
        </button>,
        nextArrow: <button style={{...buttonStyle, paddingLeft: "5px"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" className="bi bi-chevron-right"
                 viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
        </button>,
    };

    return (
        <Slide {...properties} className="slideshow">
            <div className="each-slide-effect">
                <div>
                    <section className="slide-text">
                        <span><Translator path={'slide.digital_solution'}/></span>
                        <h1><Translator path={'slide.efficiency'}/></h1>
                        <a href={routes.CONTACT_US}>
                            <PrimaryButton text={<Translator path={'slide.learn_more'}/>}/>
                        </a>
                    </section>
                    <section className="slide-image">
                        <img
                            src="https://images.unsplash.com/photo-1561414927-6d86591d0c4f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1373&q=80"
                            alt="image"
                        />
                    </section>
                </div>
            </div>
            <div className="each-slide-effect">
                <div>
                    <section className="slide-text">
                        <span><Translator path={'slide.digital_solution'}/></span>
                        <h1><Translator path={'slide.fraud'}/></h1>
                        <a href={routes.CONTACT_US}>
                            <PrimaryButton text={<Translator path={'slide.learn_more'}/>}/>
                        </a>
                    </section>
                    <section className="slide-image">
                        <img
                            src={ImageSecured}
                            alt="image"
                        />
                    </section>
                </div>
            </div>
            <div className="each-slide-effect">
                <div>
                    <section className="slide-text">
                        <span><Translator path={'slide.digital_solution'}/></span>
                        <h1><Translator path={'slide.flexibility'}/></h1>
                        <a href={routes.CONTACT_US}>
                            <PrimaryButton text={<Translator path={'slide.learn_more'}/>}/>
                        </a>
                    </section>
                    <section className="slide-image">
                        <img
                            src={ImagePortal}
                            alt="image"
                        />
                    </section>
                </div>
            </div>
        </Slide>
    );
};

export default SlideShow;