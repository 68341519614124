import {useTranslation} from 'react-i18next';
import toastHelper from "../../helpers/toast-helper";
import Translator from "../i18n/Translator";
import {useState} from "react";
import axios from 'axios';

const ContactForm = () => {
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState(false);

    const handleFormSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        const formData = new FormData(event.target);

        const requestBody = {
            code: "globetek-contact-from-landing-page",
            category: "globetek-application",
            parameters: {
                name: formData.get('name'),
                email: formData.get('email'),
                phone: formData.get('phone'),
                message: formData.get('message')
            },
            recipient: "commercial@globetek.com",
            recipientDescription: "Contact from landing",
            typeName: "br.com.supersim.service.communication.model.Message"
        };

        try {
            setDisabled(true);
            const response = await axios.post('/api/collateral/send-email', requestBody);
            console.log(response.data);
            toastHelper.showSuccess(t('toast_helper.send_email.success'))
        } catch (error) {
            console.error(error);
            toastHelper.showError(t('toast_helper.send_email.error'))
        }
    };


    return (
        <form
            className="primary-form"
            onSubmit={handleFormSubmit}
        >
            <h4 align="center">
                <Translator path={'contact.title'}/>
            </h4>
            <input
                required={true}
                type="text"
                className="primary-input"
                name="name"
                id="name"
                placeholder={t('contact.name')}
            />
            <input
                required={true}
                className="primary-input"
                id="email"
                type="email"
                name="email"
                placeholder={"email"}
            />
            <input
                required={true}
                className="primary-input"
                id="phone"
                type="number"
                name="phone"
                placeholder={t('contact.phone')}
            />
            <textarea
                id="message"
                name="message"
                className="primary-input"
                style={{minHeight: '130px'}}
                placeholder={t('contact.message')}
            />
            <div className="align-button">
                <input type="submit" className="short-button" disabled={disabled} style={{backgroundColor: disabled ? '#AFAFAF' : ''}} value={t('contact.submit')}/>
            </div>
        </form>
    );
};

export default ContactForm;
