import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import NavDropdown from "react-bootstrap/NavDropdown";

const I18n = () => {
    const {i18n, t} = useTranslation()
    const [language, setLanguage] = useState("en");


    function handleChangeLanguage(language) {
        i18n.changeLanguage(language)
        setLanguage(language)
    }

    return (
        <button className="language-button">
            <NavDropdown style={{color: 'white'}} title={i18n.language} id="basic-nav-dropdown">
                <NavDropdown.Item className={language === "en" ? 'bolder' : null}
                                  onClick={() => handleChangeLanguage('en')}>EN</NavDropdown.Item>
                <NavDropdown.Item className={language === "es" ? 'bolder' : null}
                                  onClick={() => handleChangeLanguage('es')}>ES</NavDropdown.Item>
                <NavDropdown.Item className={language === "pt" ? 'bolder' : null}
                                  onClick={() => handleChangeLanguage('pt')}>PT</NavDropdown.Item>
            </NavDropdown>
        </button>

    )
}

export default I18n