const productItemId = {
    columns: [
        {
            label: 'Name',
            field: 'name',
            sort: 'asc'
        },
        {
            label: 'Type',
            field: 'type',
            sort: 'asc'
        },
        {
            label: 'Description',
            field: 'description',
            sort: 'asc'
        },
        {
            label: 'Example',
            field: 'example',
            sort: 'asc'
        }
    ],
    rows: [
        {
            'name': 'TAG',
            'kind': 'String',
            'description': 'TAG from collateral',
            'example': '3B2DD0F',
        },
        {
            'name': 'Product Item Id',
            'kind': 'Long',
            'description': 'The product item id',
            'example': '1234',
        },
        {
            'name': 'Product Code',
            'kind': 'String',
            'description': 'Your product code',
            'example': 'Demo',
        }
    ]
}

export default productItemId;