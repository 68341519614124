const getAllCollaterals = {
    columns: [
        {
            label: 'Name',
            field: 'name',
            sort: 'asc'
        },
        {
            label: 'Type',
            field: 'type',
            sort: 'asc'
        },
        {
            label: 'Description',
            field: 'description',
            sort: 'asc'
        },
        {
            label: 'Example',
            field: 'example',
            sort: 'asc'
        }
    ],
    rows: [
        {
            'name': 'Product Code',
            'kind': 'String',
            'description': 'Your partner code',
            'example': 'Demo',
        },
        {
            'name': 'Page',
            'kind': 'Long',
            'description': 'Page number (optional parameter, default = 0)',
            'example': '0',
        },
        {
            'name': 'Size',
            'kind': 'Long',
            'description': 'Size of page (optional parameter, default = 100)',
            'example': '100',
        }
    ]
}

export default getAllCollaterals;