import React from "react";
import Navigation from "../components/navigation/Navigation";
import BadgesContainer from "../components/abous_us/BadgesContainer";
import IllustrativeGraphic from "../components/abous_us/IllustrativeGraphic";
import TextWithQuotes from "../components/abous_us/TextWithQuotes";
import PrimaryButton from "../components/common/PrimaryButton";
import Fade from "react-reveal/Fade";
import Sticker from "../assets/images/about_us/sticker.png"
import BrazilFlagIcon from "../components/abous_us/BrazilFlagIcon";
import CountUp from "react-countup";
import MediumScrollEffect from "../components/abous_us/MediumScrollEffect";
import Footer from "../components/common/Footer";
import FAQ from "../components/abous_us/FAQ";
import Translator from "../components/i18n/Translator";

function AboutUs() {
    const flexContainer = {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        gap: '30px'
    }
    return (
        <>
            <Navigation/>
            <section className="primary-container">
                <div style={flexContainer}>
                    <div>
                        <TextWithQuotes
                            title=<Translator path={'about_us.who_are_we'}/>
                            content=<Translator path={'about_us.introduction'}/>
                        />
                        <Fade left>
                            <PrimaryButton text="Contact Us"/>
                        </Fade>
                    </div>
                    <IllustrativeGraphic/>
                </div>
                <BadgesContainer/>
            </section>
            <section className="brazilian-case">
                <div className="brazilian-case-text">
                    <BrazilFlagIcon/>
                    <h2><Translator path={'about_us.results'}/></h2>
                    <p><Translator path={'about_us.risk_reduction'}/></p>
                    <Fade left>
                        <img src={Sticker} alt="Sticker"/>
                    </Fade>
                </div>
                <div className="group-number-cards">
                    <div className="card-large-primary">
                        <div>
                            <b><CountUp duration={5} end={1000000}/></b>
                            <p><Translator path={'about_us.loans'}/></p>
                        </div>
                    </div>
                    <div className="card-medium-secondary">
                        <div>
                            <b>25%</b>
                            <p><Translator path={'about_us.defaults'}/></p>
                        </div>
                    </div>
                    <div className="card-medium-secondary">
                        <div>
                            <b>66%</b>
                            <p><Translator path={'about_us.approvals'}/></p>
                        </div>
                    </div>
                </div>
            </section>
            <MediumScrollEffect/>
            <FAQ/>
            <Footer/>
        </>
    );
}

export default AboutUs;