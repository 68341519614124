import React from "react";
import Accordion from "../ui/accordion/accordion";
import Translator from "../i18n/Translator";


function FAQ() {
    return (
        <div className="faq">
            <h2><Translator path={'faq.faq'}/></h2>
            <Accordion
                active={true}
                title={<Translator path={'faq.installation'}/>}
                content={<Translator path={'faq.installation_answer'}/>}
            />
            <Accordion
                active={true}
                title={<Translator path={'faq.how_to_lock'}/>}
                content={<Translator path={'faq.how_to_lock_answer'}/>}
            />
            <Accordion
                active={true}
                title={<Translator path={'faq.billing'}/>}
                content={<Translator path={'faq.billing_answer'}/>}
            />
            <Accordion
                active={true}
                title={<Translator path={'faq.factory_reset'}/>}
                content={<Translator path={'faq.factory_reset_answer'}/>}
            />
            <Accordion
                active={true}
                title={<Translator path={'faq.compatible_devices'}/>}
                content={<Translator path={'faq.compatible_devices_answer'}/>}
            />
            <Accordion
                active={true}
                title={<Translator path={'faq.pii'}/>}
                content={<Translator path={'faq.pii_answer'}/>}
            />
        </div>
    );
}

export default FAQ;