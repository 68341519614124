import {withTranslation} from "react-i18next";
import React from 'react';
import I18n from "../i18n/i18n";

class Navigation extends React.Component {
    render() {

        return <header className={"select-language"}><I18n/></header>
    }


}

const NavigationTranslated = withTranslation()(Navigation)

export default NavigationTranslated
