import React from "react";
import Navigation from "../components/navigation/Navigation";
import ContactForm from "../components/ui/ContactForm";

class ContactUs extends React.Component {

    render() {

        return <>
            <Navigation/>
            <section className="container-all-center">
                <ContactForm/>
            </section>
        </>
    }
}

export default ContactUs;