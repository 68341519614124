import Translator from "../i18n/Translator";
import React from "react";
import Fade from "react-reveal/Fade";
import routes from "../../navigation/routes";
import PrimaryButton from "./PrimaryButton";

function Footer() {
    return (
        <footer>
            <div className="footer-contact">
                <Fade top>
                    <p><Translator path={'footer.waiting'}/></p>
                    <div className="center-grid">
                        <a href={routes.CONTACT_US}>
                            <PrimaryButton text={<Translator path={'navbar.contact'}/>}/>
                        </a>
                    </div>
                </Fade>
            </div>
            <div className="footer-list">
                <ul>
                    <li><Translator path={'footer.location'}/></li>
                    <li><Translator path={'footer.peru'}/></li>
                    <li><Translator path={'footer.colombia'}/></li>
                    <li><Translator path={'footer.guatemala'}/></li>
                    <li><Translator path={'footer.uzbekistan'}/></li>
                    <li><Translator path={'footer.bolivia'}/></li>
                    <li><Translator path={'footer.latvia'}/></li>
                </ul>
                <ul>
                    <li><Translator path={'footer.pages'}/></li>
                    <li><a href={routes.ROOT}><Translator path={'footer.solution'}/></a></li>
                    <li><a href={routes.PRODUCT}><Translator path={'footer.product'}/></a></li>
                    <li><a href={routes.ABOUT_US}><Translator path={'footer.about_us'}/></a></li>
                    <li><a href={routes.DOCS}><Translator path={'footer.documentation'}/></a></li>
                    <li><a href={routes.CONTACT_US}><Translator path={'footer.contact_us'}/></a></li>
                </ul>
                <ul>
                    <li>Legal</li>
                    <li><a href={routes.PRIVACY_POLICY} target={"_blank"}><Translator path={'footer.privacy_policy'}/></a></li>
                    <li><a href={routes.TERMS_OF_SERVICE} target={"_blank"}><Translator path={'footer.terms_of_service'}/></a></li>
                </ul>
            </div>
            <div className="footer-bottom">
                <p><Translator path={'footer.globetek'}/></p>
                <a href="https://www.linkedin.com/company/globetek-holdings/mycompany/verification/" target="_blank">
                    <i className="bi bi-linkedin"></i>
                </a>
            </div>
        </footer>

    );
}

export default Footer;