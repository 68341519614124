import React from "react";
import Navigation from "../components/navigation/Navigation";
import 'bootstrap/dist/css/bootstrap.min.css';
import Translator from "../components/i18n/Translator";
import SlideShow from "../components/ui/SlideShow";
import Footer from "../components/common/Footer";
import PartnersImage from "../assets/images/home/partners.svg"
import Fade from "react-reveal/Fade"
import PhoneBlue from "../assets/images/home/full_lock_blue.svg"

import IconGear from "../assets/images/icon/gear.svg";
import IconPadlock from "../assets/images/icon/padlock.svg";
import IconWand from "../assets/images/icon/wand.svg";
import Card from "react-bootstrap/Card";
import {motion} from "framer-motion";
import ImageArtC from "../assets/images/home/447b30.png";
import routes from "../navigation/routes";
import PrimaryButton from "../components/common/PrimaryButton";

const Home = () => {


    return (
        <>
            <Navigation/>
            <SlideShow/>
            <section className="min-vh-100 flex-two-col">
                <div className="align-vertical">
                    <div>
                        <p className="silver"><Translator path={'home.digital_solution'}/></p>
                        <br/><br/>
                        <h1 style={{maxWidth: '700px'}}><Translator path={'home.unique_needs'}/></h1>
                    </div>

                    <div className="group-list">
                        <ul className="blue-bullet-list">
                            <li><i className="bi bi-check-circle-fill"></i> <Translator
                                path={'home.reduce_credit_risk'}/></li>
                            <li><i className="bi bi-check-circle-fill"></i> <Translator path={'home.approval_rates'}/>
                            </li>
                            <li><i className="bi bi-check-circle-fill"></i> <Translator path={'home.reduce_fraud'}/>
                            </li>
                        </ul>
                        <ul className="blue-bullet-list">
                            <li><i className="bi bi-check-circle-fill"></i> <Translator path={'home.earn_money'}/></li>
                            <li><i className="bi bi-check-circle-fill"></i> <Translator path={'home.payment'}/></li>
                        </ul>
                    </div>
                </div>
                <div className="image-side">
                    <img src={PhoneBlue} alt=""/>
                </div>
            </section>

            <section className="grid-pattern section-how-it-works min-vh-100">
                <Fade top>
                    <div className="responsive-container">
                        <h3 className="title-header"><Translator path={'home.how_it_works'}/></h3>
                        <p><Translator path={'home.complete_solution'}/></p>
                    </div>
                </Fade>
                <motion.section className="home-cards-container responsive-container"
                                transition={{type: "tween", duration: 1}}
                                initial={{y: 30}}
                                whileInView={{y: 0}}
                >
                    <Card className="home-cards">
                        <Card.Body>
                            <div className="card-icon"><img src={IconGear} alt={"Icon"}/></div>
                            <br/><br/>
                            <Card.Title><Translator path={'home.set_up'}/></Card.Title>
                            <Card.Text><Translator path={'home.rules'}/></Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="home-cards">
                        <Card.Body>
                            <div className="card-icon"><img src={IconWand} alt={"Icon"}/></div>
                            <br/><br/>
                            <Card.Title><Translator path={'home.installation'}/></Card.Title>
                            <Card.Text><Translator path={'home.apk_installation'}/></Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="home-cards">
                        <Card.Body>
                            <div className="card-icon"><img src={IconPadlock} alt={"Icon"}/></div>
                            <br/><br/>
                            <Card.Title><Translator path={'home.locking'}/> </Card.Title>
                            <Card.Text><Translator path={'home.unlock_rules'}/></Card.Text>
                        </Card.Body>
                    </Card>
                </motion.section>
                <div className="align-center">
                    <a href={routes.CONTACT_US}>
                        <PrimaryButton text={<Translator path={'home.book_a_demo'}/>}/>
                    </a>
                </div>
            </section>

            <section className="grid-pattern min-vh-100">
                <header className="advantage-section-title responsive-container">
                    <p><Translator path={'home.our_competitive'}/></p>
                    <h3 className="title-header"><Translator path={'home.advantages'}/></h3>
                </header>
                <div className="main-advantages-container responsive-container">
                    <section className="advantage-card-section">
                        <div className="advantage-card-section-flex">
                            <div id="advantage-card-a" className="advantage-card">
                                <p className="percentage-indicator">80%</p>
                                <h3 className="card-title-style"><Translator path={'home.device_coverage'}/></h3>
                                <p><Translator path={'home.brands'}/></p>
                            </div>
                            <div id="advantage-card-b" className="advantage-card">
                                <p className="percentage-indicator">100%</p>
                                <h3 className="card-title-style"><Translator path={'home.customizable'}/></h3>
                                <p><Translator path={'home.not_required'}/></p>
                            </div>
                        </div>
                        <div id="advantage-card-c" className="advantage-card">
                            <h3 className="card-title-style"><Translator path={'home.flexible'}/></h3>
                            <p><b><Translator path={'home.more_flexible'}/> </b>
                                <Translator path={'home.lock_implementation'}/></p>
                        </div>
                    </section>
                    <div className="right-mobile">
                        <img className="responsive-image image-right-mobile" src={ImageArtC} alt={""}/>
                    </div>

                </div>
            </section>
            <section className="vh-100 center-grid pattern">

                <h1><Translator path={'home.our_partners'}/></h1>

                <p className="phrase"><Translator path={'home.solution_for_partners'}/></p>
                <div className="center-grid">
                    <img className="responsive-image" src={PartnersImage} alt="image"/>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default Home;