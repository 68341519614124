import Translator from "../../components/i18n/Translator";
import React from "react";
function CommercialCard(props) {
    return (
        <div className="glass-effect">
            <header className="card-header">
                <h2>{props.title}</h2>
                <img src={props.icon} alt="icon"/>
            </header>
            <div className="card-body" style={props.height ? { height: props.height } : null}>
                <div>{props.body}</div>
            </div>
        </div>
    );
}

export default CommercialCard;